import React from "react";
import { mod } from "../../utils/mod";
import Slide from "./Slide";

interface CarouselItem {
  key: number;
  content: JSX.Element;
}

interface ThreeDCarouselProps {
  index: number;
  items: CarouselItem[];
}

const ThreeDCarousel: React.FC<ThreeDCarouselProps> = props => {
  const { index, items } = props;
  const numOfElementsOnEachSide = 2;

  const [presentedItems, setPresentedItems] =
    React.useState<CarouselItem[]>(items);

  React.useEffect(() => {
    const tmpItems = [];
    for (
      let i = -numOfElementsOnEachSide;
      i < numOfElementsOnEachSide + 1;
      i++
    ) {
      tmpItems.push(items[mod(index + i, items.length)]);
    }
    setPresentedItems(tmpItems);
  }, [items, index]);

  return (
    <div className="relative w-full h-full">
      {presentedItems?.map((item, idx) => {
        return <Slide key={item.key} content={item.content} index={idx} />;
      })}
    </div>
  );
};

export default ThreeDCarousel;
