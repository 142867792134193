import React from "react";
import useMedia from "use-media";
import Brands, { BrandObject } from "../../../Brands";
import CartographLogo from "../../../Brands/BrandSvgs/cartograph.svg";
import CrocsLogo from "../../../Brands/BrandSvgs/crocs.svg";
import DeltaLogo from "../../../Brands/BrandSvgs/delta.svg";
import MikesLogo from "../../../Brands/BrandSvgs/mikes.svg";
import MimisLogo from "../../../Brands/BrandSvgs/mimis.svg";
import ThreeDCarousel from "../../../ThreeDCarousel";
import Cartograph from "./assets/cartograph.jpg";
import Crocs from "./assets/crocs.jpg";
import DeltaGalil from "./assets/delta-galil.jpg";
import MikesHot from "./assets/mikes-hot.jpg";
import MimiRock from "./assets/mimi-rock.jpg";
import CustomerCarouselItem, {
  CustomerCarouselItemVariant
} from "./CustomerCarouselItem";

const CustomerCarousel: React.FC<{}> = () => {
  const slides = [
    {
      key: 1,
      content: (
        <CustomerCarouselItem
          text={
            "How Mimi's Rock Unlocked Scalability and Growth for Multiple Brands"
          }
          backgroundImageURL={MimiRock}
          customerLogoSVG={<MimisLogo />}
          onClick={() =>
            window?.open("https://vimeo.com/454520127/7af74a2aae", "_blank")
          }
          variant={CustomerCarouselItemVariant.video}
        />
      )
    },
    {
      key: 2,
      content: (
        <CustomerCarouselItem
          text={"Fueling scalable agency growth at Cartograph with Perpetua"}
          backgroundImageURL={Cartograph}
          customerLogoSVG={<CartographLogo />}
          onClick={() =>
            window?.open(
              "https://perpetua.io/blog-fueling-scalable-agency-growth-at-cartograph-with-perpetua/",
              "_blank"
            )
          }
        />
      )
    },
    {
      key: 3,
      content: (
        <CustomerCarouselItem
          text={
            "The Secret Sauce: Mike's Hot Honey Climbs Best Seller Rank with Perpetua"
          }
          backgroundImageURL={MikesHot}
          customerLogoSVG={<MikesLogo />}
          onClick={() =>
            window?.open("https://perpetua.io/blog-mikes-hot-honey/", "_blank")
          }
        />
      )
    },
    {
      key: 4,
      content: (
        <CustomerCarouselItem
          text={
            "Growing Market Share and Sales at Crocs with Perpetua’s Advanced Targeting"
          }
          backgroundImageURL={Crocs}
          customerLogoSVG={<CrocsLogo />}
          onClick={() =>
            window?.open(
              "https://perpetua.io/blog-growing-market-share-and-sales-at-crocs-with-perpetua's-advanced-targeting/",
              "_blank"
            )
          }
        />
      )
    },
    {
      key: 5,
      content: (
        <CustomerCarouselItem
          text={
            "Managing Amazon Operations at Scale: Delta Galil grows with Perpetua"
          }
          backgroundImageURL={DeltaGalil}
          customerLogoSVG={<DeltaLogo />}
          onClick={() =>
            window?.open(
              "https://perpetua.io/blog-amazon-operations-delta-galil/",
              "_blank"
            )
          }
        />
      )
    }
  ];

  const [carouselIndex, setCarouselIndex] = React.useState<number>(0);
  const interval = React.useRef(null);
  const isTablet = useMedia({ minWidth: "768px" });
  const isDesktop = useMedia({ minWidth: "1024px" });

  const brandsList: BrandObject[] = [
    <MimisLogo />,
    <CartographLogo />,
    <MikesLogo />,
    <CrocsLogo />,
    <DeltaLogo />
  ].map((b, idx) => ({
    src: b,
    filter: carouselIndex !== idx,
    onClick: () => setCarouselIndex(idx)
  }));

  let size: React.CSSProperties = {
    height: "247px"
  };
  if (isDesktop) {
    size = {
      height: "500px"
    };
  } else if (isTablet) {
    size = {
      height: "247px"
    };
  }

  React.useEffect(() => {
    interval.current = setInterval(() => {
      setCarouselIndex((carouselIndex + 1) % 5);
    }, 8000);
    return () => {
      clearInterval(interval?.current);
    };
  }, [carouselIndex]);

  return (
    <div className="relative">
      <div style={{ ...size }} className="lg:mb-16 mb-12">
        <ThreeDCarousel items={slides} index={carouselIndex} />
      </div>
      <Brands brandsList={brandsList} />
    </div>
  );
};

export default CustomerCarousel;
