import classNames from "classnames";
import React from "react";
import { animated, useSpring } from "react-spring";

interface ThreeDCarouselSlideProps {
  index: number;
  content: JSX.Element;
}

const Slide: React.FC<ThreeDCarouselSlideProps> = props => {
  const { index, content } = props;

  const numOfElementsOnEachSide = 2;
  const offsetFromCenter = index - numOfElementsOnEachSide;
  const distanceFactor =
    1 - Math.abs((offsetFromCenter * 0.4) / (numOfElementsOnEachSide + 1));

  const translateX = -50 + offsetFromCenter / (numOfElementsOnEachSide + 1);

  const animation = useSpring({
    transform: `translateY(-50%) translateX(${translateX}%) scale(${distanceFactor})`,
    left: `${50 + (offsetFromCenter * 25) / numOfElementsOnEachSide}%`
  });

  return (
    <animated.div
      className={classNames(
        "absolute h-full top-1/2 w-max flex items-center justify-center origin-center rounded-xl overflow-hidden",
        { shadow: offsetFromCenter }
      )}
      style={{
        ...animation,
        zIndex: Math.abs(Math.abs(offsetFromCenter) - 2) as any,
        maxHeight: "max-content"
      }}
    >
      {content}
    </animated.div>
  );
};

export default Slide;
