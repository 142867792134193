import React from "react";
import useMedia from "use-media";
import Notes from "../assets/notes.svg";
import Play from "../assets/play.svg";

export enum CustomerCarouselItemVariant {
  text = "text",
  video = "video"
}

interface CustomerCarouselItemProps {
  text: string;
  backgroundImageURL: string;
  customerLogoSVG?: JSX.Element;
  variant?: CustomerCarouselItemVariant;
  onClick?: () => void;
}

const CustomerCarouselItem: React.FC<CustomerCarouselItemProps> = props => {
  const { text, backgroundImageURL, customerLogoSVG, variant, onClick } = props;

  const isTablet = useMedia({ minWidth: "768px" });
  const isDesktop = useMedia({ minWidth: "1024px" });

  // TODO: Add this to tailwind?
  let cardSize: React.CSSProperties = {
    width: "366px",
    height: "247px"
  };
  if (isDesktop) {
    cardSize = {
      width: "954px",
      height: "500px"
    };
  } else if (isTablet) {
    cardSize = {
      width: "472px",
      height: "247px"
    };
  }

  return (
    <div
      className={`flex flex-col justify-between text-left p-13 w-full cursor-pointer`}
      style={{
        ...cardSize,
        background: `url(${backgroundImageURL})`,
        maxWidth: "100%"
      }}
      onClick={onClick}
    >
      <p
        className="text-3xl font-semibold lg:text-5xl lg:font-bold text-white"
        style={{ textShadow: "0px 4px 6px rgba(0, 0, 0, 0.16)" }}
      >
        {text}
      </p>
      <div className="flex items-center justify-between">
        <span style={{ filter: "brightness(0) invert(1)" }}>
          {customerLogoSVG}
        </span>
        <div
          className="flex items-center justify-center bg-white bg-opacity-10 rounded-full"
          style={{ backdropFilter: "blur(4px)", height: "72px", width: "72px" }}
        >
          {variant === CustomerCarouselItemVariant.text ? <Notes /> : <Play />}
        </div>
      </div>
    </div>
  );
};

export default CustomerCarouselItem;
