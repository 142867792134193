import React from "react";
import Section from "../../../containers/Section";
import Header, { HeaderVariant } from "../../Header";
import CustomerCarousel from "./CustomerCarousel";

interface CustomerStoriesProps {
  title: string;
  description: string;
}

const CustomerStoriesSection: React.FC<CustomerStoriesProps> = props => {
  const { title, description } = props;

  return (
    <div className="rounded-tr-3xl bg-gradient-to-b from-blue-3-light to-white bg-opacity-40">
      <Section>
        <div className="lg:py-36 md:py-24 py-20 text-center">
          <Header
            variant={HeaderVariant.h5}
            className="m-auto whitespace-nowrap max-w-min text-gradient bg-gradient-to-r from-indigo-1 to-blue-1 mb-3"
          >
            {title}
          </Header>
          <Header variant={HeaderVariant.h3} className="mb-18">
            {description && description}
          </Header>
          <CustomerCarousel />
        </div>
      </Section>
    </div>
  );
};

export default CustomerStoriesSection;
